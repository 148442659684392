.homepage-content {
  font-size: var(--font-size-large);
  margin: var(--margin) 0;
}

.teaser-video {
  width: 100%;
  height: auto;
  border-radius: var(--border-radius);
}

.teaser-video:focus {
  outline: none;
}

.teaser-video-desktop {
  display: none;
}

.hompage-cta-grid {
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: var(--margin);
}

.homepage-cta {
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepage-newsletter form,
.homepage-newsletter button {
  margin-top: var(--margin);
}

@media screen and (min-width: 768px) {
  .hompage-cta-grid {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: none;
  }

  .homepage-newsletter {
    grid-row: 1;
    grid-column: 1;
  }

  .homepage-cta {
    grid-row: 1;
    grid-column: 2;
  }

  .teaser-video-mobile {
    display: none;
  }
  
  .teaser-video-desktop {
    display: block;
  }
}
